import './App.css'
import { Routes, Route } from 'react-router-dom';
import { Product } from './components/Product';
import { ProductProvider } from './context/ProductProvider';
import { ViewProduct } from './components/ViewProduct';
import { NavBar } from './components/NavBar';
import Banner from './components/Banners/Banner';
import { ViewPay } from './components/ViewPay';
import { ViewConfirm } from './components/ViewConfirm';

function App() {
  return (
    <ProductProvider>
       <NavBar></NavBar> 
       <Routes>
        <Route path="/" element = {<Product></Product>}></Route>
        <Route path="/product-details" element = {<ViewProduct></ViewProduct>}></Route>
         <Route path="/product-pay" element={<ViewPay />}  />
         <Route path="/confirm-pay" element={<ViewConfirm />}  />
      </Routes>
    </ProductProvider>
  )
}

export default App
